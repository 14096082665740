import React from 'react';
import { Spinner, Flex } from '@chakra-ui/react';

const LoadingSpinner = () => {
  return (
    <Flex
      width="100%"
      height="100vh" // Use 100vh to take the full height of the viewport
      alignItems="center" // Align children vertically
      justifyContent="center" // Align children horizontally
    >
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
    </Flex>
  );
};

export default LoadingSpinner;