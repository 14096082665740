import React from "react";
import ReactMarkdown from 'react-markdown';
import { useSettings } from "../../../../components/settings/SettingsContext";
import "../Conversation.css";
import { useTranslation } from "react-i18next";

export default function MessageItem({ bubble }) {
  const { t } = useTranslation();

  const isAssistant = bubble.role === "assistant";
  const messageClass = isAssistant ? "chatbot-message" : "user-message";
  const messageContainerClass = isAssistant ? "chatbot-container" : "user-container";
  const roleDisplay = isAssistant ? "Assistant" : t("conversations.user");
  //convert from int date
  const date = new Date(bubble.created_at * 1000);
  const formattedDate = date.toLocaleString();

  const { settings } = useSettings();
  return (
    <div className="message-container">
      {isAssistant ? (
        <img
          className="assitant-image"
          src={settings.assitantImage}
          alt="Assistant"
        />
      ) : null}
      <div className={messageContainerClass}>
        <div className={messageClass}>
          <p>{roleDisplay}</p>
          <ReactMarkdown>{bubble.value}</ReactMarkdown>
        </div>
        <p className="timestamp">{formattedDate}</p>
      </div>
    </div>
  );
}
