import React from "react";
import DefaultAuth from "layouts/auth/Default";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

export default function ForgotPassword() {
  const textColor = useColorModeValue("navy.700", "white");

  const [email, setEmail] = React.useState("");
  const [isEmailSent, setIsEmailSent] = React.useState(false);

  const sendEmail = async () => {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email)
      .then(() => {
        console.info("Successfully update password.");
        setIsEmailSent(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Could not update password : ", errorMessage);
      });
  };

  return (
    <DefaultAuth>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Retrieve Password
          </Heading>
        </Box>
      </Flex>

      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "420px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <Text fontSize="l" fontWeight="500" paddingBottom="2em">
          Please enter your email to create a new password
        </Text>
        <FormControl>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Email
          </FormLabel>
          <Input
            isRequired={true}
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="email"
            placeholder="hello@quebgpt.com"
            mb="24px"
            fontWeight="500"
            size="lg"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            onClick={sendEmail}
          >
            Send an email
          </Button>
        </FormControl>
        {isEmailSent ? (
          <Text fontSize="l" color="green" fontWeight="500" paddingBottom="2em">
            An email has been sent. Please Check your emails to reset your
            password
            <NavLink to="/auth/sign-in">
              <Text color="blue" as="span" ms="5px" fontWeight="500">
                and sign in
              </Text>
            </NavLink>
          </Text>
        ) : null}
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
          maxW="100%"
          mt="0px"
        ></Flex>
      </Flex>
    </DefaultAuth>
  );
}
