import React from "react"
import { Box } from "@chakra-ui/react";
import "./Sandbox.css";
import { AuthContext } from "contexts/AuthContext";

export default function SandBox() {
  const { userInfos } = React.useContext(AuthContext);
  const token = localStorage.getItem("jwtToken");
  const clientWebsiteURL = `https://capture-wetiwctgqq-nn.a.run.app/v1/sandbox/?vf_id=${userInfos.vf_id}&token=${token}`;

  return (
    <Box
      pt={{ base: "180px", md: "80px", xl: "80px" }}
      px={0} /* Set left and right padding to 0, overrule this if required by design */
      height="calc(100vh - 80px)" /* Adjust the value 80px to the actual height of your navigation/header */
      position="relative" /* This establishes a containing block for the absolute positioned iframe */
    >
      <div 
        className="sandbox-main-content" 
        style={{ 
          position: 'absolute', /* Absolutely position our iframe container */
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0 /* This makes the container fill the entire Box element */
        }}
      >
        <iframe
          title="client_website"
          src={clientWebsiteURL}
          style={{ 
            width: "100%", 
            height: "100%", /* Make the iframe take the full height of its parent */
            border: "none",
            transform: 'translateY(40px)'
          }}
          allowFullScreen
        />
      </div>
    </Box>
  );
}
