import React, { useEffect, useState } from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, IconButton } from '@chakra-ui/react';
import { MdDelete } from "react-icons/md";
import { axiosInstance } from "api/apiClient";
import { AuthContext } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import LoadingSpinner from '../../../components/spinner/spinner';
import ConfirmationModal from '../../../components/confirmation/ConfirmationModal';
import { useHistory } from 'react-router-dom';

export default function Leads() {
    const { userInfos, setSelectedLeadId } = React.useContext(AuthContext);
    const { t } = useTranslation();
    const history = useHistory();

    const [leads, setLeads] = useState([]);
    const [loadingLeads, setLoadingLeads] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    useEffect(() => {
        let isMounted = true; // Flag to track mount state

        const fetchLeads = async () => {
            try {
              const response = await axiosInstance.get(`firestore/get_leads?client_id=${userInfos.client_id}`);;
              const sortedLeads = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
              if (isMounted) {
                setLeads(sortedLeads);
                setLoadingLeads(false); // End loading after setting Leads
              }
            } catch (error) {
              console.error("Error fetching lead:", error);
              if (isMounted) {
                setLoadingLeads(false); // End loading if there was an error
              }
            }
        };

        fetchLeads();

        return () => {
            isMounted = false; // Clean up function setting flag to false
        };

    }, [userInfos.client_id]);

    const handleDeleteClick = (id) => {
        return () => {
            setDeleteId(id);
            setIsModalOpen(true);
        };
      };
    
    const handleConfirmDelete = async () => {
    try {
        await axiosInstance.post(`firestore/delete_lead?thread_id=${deleteId}`);

        // If successful, update the state to remove the lead
        setLeads((prevLeads) => prevLeads.filter((lead) => lead.id !== deleteId));
        setIsModalOpen(false);
    } catch (error) {
        console.error('Error deleting lead:', error);
    }
    };

    const handleIdClick = (id) => {
        setSelectedLeadId(id);
        history.push("/admin/conversations");
    };

    if (loadingLeads) {
        // Return a loading spinner if we are in the loading state
        return <LoadingSpinner />;
    }

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }} height="calc(100vh - 80px - 20px)" overflow="hidden">
            <TableContainer height="calc(100vh - 80px - 20px - 40px)" overflowY="auto">
                <Table variant="simple">
                    <Thead position="sticky" top={0} bg="gray.100" zIndex={1}>
                        <Tr>
                            <Th>{t('leads.name')}</Th>
                            <Th>Date</Th>
                            <Th>{t('leads.email')}</Th>
                            <Th>Conversation</Th>
                            <Th>{t('delete')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {leads.map((lead, index) => (
                            <Tr key={index}>
                                <Td>{lead.lead_name}</Td>
                                <Td>{lead.date}</Td>
                                <Td>{lead.lead_email}</Td>
                                <Td>
                                    <span
                                        onClick={() => handleIdClick(lead.id)}
                                        style={{ color: '#4279bd', textDecoration: 'underline', cursor: 'pointer' }}
                                        >
                                        {lead.id}
                                    </span>
                                </Td>
                                <Td >
                                    <IconButton
                                        icon={<MdDelete />}
                                        aria-label="Delete lead"
                                        onClick={handleDeleteClick(lead.id)}
                                        sx={{
                                            color: '#a22323',
                                            backgroundColor: 'transparent',
                                            _hover: { backgroundColor: 'transparent' },
                                            width: '24px',
                                            height: '24px',
                                            padding: '4px',
                                        }}
                                    />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>

            <ConfirmationModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={handleConfirmDelete}
                title={t("leads.delete_lead")}
                body={t("leads.delete_lead_conf")}
            />
        </Box>
    );
}