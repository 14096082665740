import React from 'react'
import { Icon } from "@chakra-ui/react";
import { MdInfo } from "react-icons/md";
import MessageItem from './MessageItem';
import "../Conversation.css";
import { useTranslation } from "react-i18next";

export default function ConversationItem(props) {
    const { displayed } = props;
    const { t } = useTranslation();
    
    if (displayed.source) {
        if (displayed.source.url == "no source url found") {
            displayed.source.url = "sandbox";
        }
    }

  return (
    <div className='selected-conversation-container'>
        <div className='selected-conversation-infos'>
            <Icon as={MdInfo} width='20px' height='20px' color='inherit' float='right'/>
            <p>ID : {displayed.thread_id}</p>
            <p>Assistant : {displayed.assistant_id}</p>
            <p>{t("conversations.device")} : {displayed.source ? displayed.source.device : 'N/A'}</p>
            <p>Source : {displayed.source ? displayed.source.url : 'N/A'}</p>
        </div>
     
        <div className='selected-conversation-content'>
            {displayed.messages.map(message => (
                <div key={message.message_id}>
                    <MessageItem bubble={message}/>
                </div>
            ))}
        </div>
    </div>
  )
}
