import React from "react";

// Chakra imports
import { Flex, Image } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {

  return (
    <Flex align='center' direction='column'>
      <Image 
        src="https://storage.googleapis.com/capture-assets/QBG_Logo_Complet_Couleur.png"
        alt="Capture Logo"
        w={{ base: "100px", sm: "150px", md: "200px" }} // Responsive width
      />
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
