import React, { useEffect, useState, useRef } from "react";
import { Box } from "@chakra-ui/react";
import ConversationItem from "./components/ConversationItem";
import "./Conversation.css";
import { axiosInstance } from "api/apiClient";
import ConversationPreview from "./components/ConversationPreview";
import ConfirmationModal from '../../../components/confirmation/ConfirmationModal';
import { AuthContext } from "contexts/AuthContext";
import LoadingSpinner from '../../../components/spinner/spinner';
import { useTranslation } from "react-i18next";

export default function Conversations() {
  const { userInfos, selectedLeadId, setSelectedLeadId } = React.useContext(AuthContext);
  const { t } = useTranslation();
  
  const [conversations, setConversations] = useState([]);
  const [loadingConversations, setLoadingConversations] = useState(true);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const selectedConversationRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    let isMounted = true; // Flag to track mount state

    const fetchConversations = async () => {
      try {
        const response = await axiosInstance.get(`firestore/get_conversations?client_id=${userInfos.client_id}`);
        const sortedConvos = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        if (isMounted) {
          setConversations(sortedConvos);
          if (selectedLeadId) {
            const conv = sortedConvos.find((c) => c.thread_id === selectedLeadId);
            setSelectedConversation(conv);
            setSelectedLeadId(null);
          }
          else if (sortedConvos.length > 0) {
            setSelectedConversation(sortedConvos[0]);
          }
          setLoadingConversations(false); // End loading after setting conversations
        }
      } catch (error) {
        console.error("Error fetching conversation:", error);
        if (isMounted) {
          setLoadingConversations(false); // End loading if there was an error
        }
      }
    };

    fetchConversations();

    return () => {
      isMounted = false; // Clean up function setting flag to false
    };
  }, [userInfos.client_id]); // Dependencies array

  if (loadingConversations) {
    // Return a loading spinner if we are in the loading state
    return <LoadingSpinner />;
  }

  const handleSelectedConvo = (id) => {
    const conv = conversations.find((c) => c.thread_id === id);
    setSelectedConversation(conv);

    // Scroll to the top of the selected conversation container
    if (selectedConversationRef.current) {
      selectedConversationRef.current.scrollTop = 0;
    }
  };

  const handleDeleteClick = (id) => {
    return () => {
      setDeleteId(id);
      setIsModalOpen(true);
    };
  };

  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.post(`firestore/delete_conversation?thread_id=${deleteId}`);

      // Remove the deleted conversation from the state
      setConversations((prevConversations) =>
        prevConversations.filter((conversation) => conversation.thread_id !== deleteId)
      );

      // If the selected conversation is being deleted, reset the selected conversation
      if (selectedConversation && selectedConversation.thread_id === deleteId) {
        setSelectedConversation(null);
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <div className="conversation-container">
        <div className="conversation-list">
          {conversations.map((conversation, index) => (
            <ConversationPreview
              key={index}
              index={index}
              preview={conversation}
              onSelect={() => handleSelectedConvo(conversation.thread_id)}
              onDelete={handleDeleteClick(conversation.thread_id)} // Pass the delete handler
              isSelected={selectedConversation && selectedConversation.thread_id === conversation.thread_id}
            />
          ))}
        </div>
        <div className="selected-conversation-container" ref={selectedConversationRef}>
          {selectedConversation && (
            <div>
              <ConversationItem displayed={selectedConversation} />
            </div>
          )}
        </div>
      </div>

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmDelete}
        title={t("conversations.delete_conversation")}
        body={t("conversations.delete_conv_conf")}
      />
    </Box>
  );
}
