import React, { useEffect, useState } from "react";
import "./GeneralSettings.css";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "contexts/AuthContext";
import { axiosInstance } from "api/apiClient";
import { getAuth, updateEmail, verifyBeforeUpdateEmail } from "firebase/auth";

export default function GeneralSettings() {
  const { t } = useTranslation();
  const { user, userInfos, signOut } = React.useContext(AuthContext);
  const setSettingsPath = `firestore/set_fb_user`;
  const [email, setEmail] = React.useState("");

  const [clientInfos, setClientInfos] = useState({
    uid: "",
    client_id: "",
    vf_id: "",
    last_name: "",
    first_name: "",
    company_name: "",
    email: "",
    payment_date: "",
    payment_amount: 0,
  });

  const [saveChangesMessage, setSaveChanges] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const textColorError = isError ? "pink.600" : "green";

  const fetchClientInfos = async () => {
    setClientInfos(userInfos);
    setEmail(userInfos.email);
  };

  useEffect(() => {
    fetchClientInfos();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientInfos((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = {
        uid: clientInfos.uid,
        client_id: clientInfos.client_id,
        vf_id: clientInfos.vf_id,
        company_name: clientInfos.company_name,
        last_name: clientInfos.last_name,
        first_name: clientInfos.first_name,
        email: clientInfos.email,
        payment_date: clientInfos.payment_date,
        payment_amount: clientInfos.payment_amount,
      };

      const response = await axiosInstance.post(setSettingsPath, body, config);
      console.info("Successfully update client infos : ", response);
      setSaveChanges(t("general_settings.save_message_success"));
      setIsError(false);
      if (response.data.email !== email) {
        const auth = getAuth();
        await verifyBeforeUpdateEmail(user, response.data.email);
        await signOut(auth);
        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating client infos:", error);
      setSaveChanges(t("general_settings.save_message_error"));
      setIsError(true);
    }
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <form className="general-settings-form" onSubmit={handleSubmit}>
        <FormControl id="last_name">
          <FormLabel>{t("general_settings.name")}</FormLabel>
          <Input
            type="text"
            value={clientInfos.last_name}
            placeholder={t("general_settings.name")}
            onChange={handleInputChange}
            name="last_name"
          />
        </FormControl>
        <FormControl id="first_name">
          <FormLabel>{t("general_settings.firstname")}</FormLabel>
          <Input
            type="text"
            value={clientInfos.first_name}
            placeholder={t("general_settings.firstname")}
            onChange={handleInputChange}
            name="first_name"
          />
        </FormControl>
        <FormControl id="company_name">
          <FormLabel>{t("general_settings.company_name")}</FormLabel>
          <Input
            type="text"
            value={clientInfos.company_name}
            placeholder={t("general_settings.company_name")}
            onChange={handleInputChange}
            name="company_name"
          />
        </FormControl>
        <FormControl id="email">
          <FormLabel>{t("general_settings.email")}</FormLabel>
          <Text paddingLeft="1em" color="rgb(152, 52, 52)" >{t("general_settings.email_warning")}</Text>
          <Input
            type="email"
            value={clientInfos.email}
            placeholder={t("general_settings.email")}
            onChange={handleInputChange}
            name="email"
          />
        </FormControl>
        {/* <FormControl id="payment_date">
          <FormLabel>{t("general_settings.payment_date")}</FormLabel>
          <Input
            type="date"
            value={clientInfos.payment_date}
            placeholder={t("general_settings.payment_date")}
            onChange={handleInputChange}
            name="payment_date"
          />
        </FormControl>
        <FormControl id="payment_amount">
          <FormLabel>{t("general_settings.payment_amount")}</FormLabel>
          <Input
            type="number"
            value={clientInfos.payment_amount}
            placeholder={t("general_settings.payment_amount")}
            onChange={handleInputChange}
            name="payment_amount"
          />
        </FormControl> */}
        <Text color={textColorError} fontSize="m" fontWeight="500">
          {saveChangesMessage}
        </Text>
        <Button type="submit">{t("save_button")}</Button>
      </form>
    </Box>
  );
}
