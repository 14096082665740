import { Button, IconButton, Box } from "@chakra-ui/react";
import React from "react";
import { MdDelete } from "react-icons/md";

export default function ConversationPreview({ index, preview, onSelect, isSelected, onDelete}) {
  const cleanTimestamp = new Date(preview.timestamp).toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  });

  return (
    <Box // Use Box instead of Button to avoid nested button issue
      className={`conversation-preview-button ${isSelected ? 'selected' : ''}`}
      onClick={onSelect}
      sx={{
        px: '2em',
        py: '1em',
        margin: '0.5em',
        width: '100%',
        textAlign: 'left',
        backgroundColor: isSelected ? '#bfbfbf' : 'white', // Custom background
        _hover: { backgroundColor: isSelected ? '#bfbfbf' : '#d9d9d9' }, // Custom hover
        position: 'relative', // Enable positioning for child elements
        cursor: 'pointer', // Ensure the Box behaves like a button
        borderRadius: '8px', // Add border radius for rounded corners
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Add box shadow for depth
      }}
    >
      <Box className="conversation-preview">
        <h1>{cleanTimestamp}</h1>
        <div className="conversation-preview-infos">
          <p className="conversation-preview-message">{preview.messages[0].value}</p>
        </div>
      </Box>
      <IconButton
        icon={<MdDelete />}
        aria-label="Delete conversation"
        onClick={(e) => {
          e.stopPropagation(); 
          onDelete(); 
        }}
        sx={{
          position: 'absolute',
          top: '25%', 
          right: '0px',
          transform: 'translateY(-50%)', 
          width: '24px',
          height: '24px', 
          padding: '4px', 
          color: '#a22323',
          backgroundColor: 'transparent',
          _hover: { backgroundColor:'transparent' },
        }}
      />
    </Box>
  );
}
