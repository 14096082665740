import React, { createContext, useState, useContext } from 'react';

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({
    assitantImage: "https://storage.googleapis.com/capture-assets/QBG_Logo_Icone.jpg",
    chatbotColor: '#000000',
    chatbotName: 'Quebgpt',
    chatbotDescription: 'An amazing funny chatbot',
  });

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);