// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    margin: 1em;
}

.settings-container p {
    color: rgb(161, 158, 158);
}

.settings-container icon {
    fill: yellow;
}

.settings-widget-box {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 30em;
    margin-top: 1em;
}`, "",{"version":3,"sources":["webpack://./src/views/admin/chatbotSettings/ChatbotSettings.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,QAAQ;IACR,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,WAAW;IACX,eAAe;AACnB","sourcesContent":[".settings-container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 1em;\n    margin: 1em;\n}\n\n.settings-container p {\n    color: rgb(161, 158, 158);\n}\n\n.settings-container icon {\n    fill: yellow;\n}\n\n.settings-widget-box {\n    display: flex;\n    flex-direction: column;\n    gap: 1em;\n    width: 30em;\n    margin-top: 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
