// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sandbox-main-content {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Changed from center to stretch to allow the iframe to fill the width */
  gap: 2em; /* Correct unit from 'm' to 'em' */
  margin: 4em auto; /* Keep the margin top and bottom, auto for left and right if centered alignment is wanted */
  width: auto; /* Or you could use '100%' if this element should always take the full width of its parent */
}

.sandbox-title{
  font-size: 4em;
  width: auto; /* Make sure this doesn't have a fixed width if you want to center it */
  text-align: center; /* Center the text if that's desired */
}
`, "",{"version":3,"sources":["webpack://./src/views/admin/sandbox/Sandbox.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB,EAAE,yEAAyE;EAC/F,QAAQ,EAAE,kCAAkC;EAC5C,gBAAgB,EAAE,4FAA4F;EAC9G,WAAW,EAAE,4FAA4F;AAC3G;;AAEA;EACE,cAAc;EACd,WAAW,EAAE,uEAAuE;EACpF,kBAAkB,EAAE,sCAAsC;AAC5D","sourcesContent":[".sandbox-main-content {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch; /* Changed from center to stretch to allow the iframe to fill the width */\n  gap: 2em; /* Correct unit from 'm' to 'em' */\n  margin: 4em auto; /* Keep the margin top and bottom, auto for left and right if centered alignment is wanted */\n  width: auto; /* Or you could use '100%' if this element should always take the full width of its parent */\n}\n\n.sandbox-title{\n  font-size: 4em;\n  width: auto; /* Make sure this doesn't have a fixed width if you want to center it */\n  text-align: center; /* Center the text if that's desired */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
