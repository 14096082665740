import React, { useRef, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import { MdColorLens, MdDisplaySettings } from "react-icons/md";
import "./ChatbotSettings.css";
import { ChromePicker } from "react-color";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { useSettings } from '../../../components/settings/SettingsContext';
import { useTranslation } from "react-i18next";

export default function ChatbotSettings() {
  const { t } = useTranslation();

  const [name, setName] = React.useState("chatbotElephant");
  const [description, setDescription] = React.useState("A cute elephant chatbot");

  const { settings, setSettings } = useSettings();

  const [selectedColor, setSelectedColor] = React.useState("#000000");
  const inputRef = useRef(null);

  const handleImageUpload = () => {
    inputRef.current.click();

    if (previewImage) {
      setSettings({
        ...settings,
        assitantImage: previewImage,
      });
    }
  };
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmitInfos = (e) => {
    console.info("Chatbot Infos : ", name, description)
    //TODO : Send settings to backend to save
  };

  const handleSubmitAppearance = (e) => {
    console.info("Chatbot Appearance : ", selectedColor, previewImage)
    //TODO : Send settings to backend to save
  };

  const [previewImage, setPreviewImage] = useState(null);

  const [isGeneralOpen, setGeneralIsOpen] = useState(false);
  const [isAppearanceOpen, setAppearanceIsOpen] = useState(false);

  const toggleGeneral = () => {
    setGeneralIsOpen(!isGeneralOpen);
  };

  const toggleAppearance = () => {
    setAppearanceIsOpen(!isAppearanceOpen);
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Accordion>
        <AccordionItem>
            <AccordionButton onClick={toggleGeneral}>
              <Box flex="1" textAlign="left" className="settings-container">
                <Icon as={MdDisplaySettings} boxSize={8} color="inherit" />
                <div>
                  <h1>{t("chatbot_settings.general")}</h1>
                  <p>{t("chatbot_settings.general_description")}</p>
                </div>
              </Box>
              {isGeneralOpen ? <MdExpandLess /> : <MdExpandMore />}
            </AccordionButton>
          <AccordionPanel marginLeft="4em" pb={4} display={isGeneralOpen ? "block" : "none"}>
            <Text fontWeight="bold">{t("chatbot_settings.chatbot_name")}</Text>
            <Input
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              mb={4}
            />
            <Text fontWeight="bold">{t("chatbot_settings.chatbot_description")}</Text>
            <Input
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              mb={4}
            />
            <Button onClick={handleSubmitInfos}>{t("save_button")}</Button>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Accordion>
        <AccordionItem>
            <AccordionButton onClick={toggleAppearance}>
              <Box flex="1" textAlign="left" className="settings-container">
                <Icon as={MdColorLens} boxSize={8} color="inherit" />
                <div>
                  <h1>{t("chatbot_settings.appearance")}</h1>
                  <p>{t("chatbot_settings.appearance_description")}</p>
                </div>
              </Box>
              {isAppearanceOpen ? <MdExpandLess /> : <MdExpandMore />}
            </AccordionButton>
          <AccordionPanel marginLeft="4em" pb={4} display={isAppearanceOpen ? "block" : "none"}>
            <Box mb={4} className="settings-widget-box">
              <Text fontWeight="bold">{t("chatbot_settings.chatbot_color")}</Text>
              <ChromePicker
                color={selectedColor}
                onChange={(color) => setSelectedColor(color.hex)}
              />
            </Box>
            <Box mb={4}>
              <input
                type="file"
                accept="image/*"
                ref={inputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <div className="settings-widget-box">
                <Text fontWeight="bold">{t("chatbot_settings.chatbot_image")}</Text>
                <Button width="8em" onClick={handleImageUpload}>{t("chatbot_settings.upload_button")}</Button>
                {previewImage && <Image src={previewImage} alt="Preview" mt={4} maxW="200px" />}
              </div>
            </Box>
            <Button onClick={handleSubmitAppearance}>{t("save_button")}</Button>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
