import React from "react";

import {
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";

import firebaseAuth from "config/firebaseConfig";
import axiosInstance from "api/apiClient";


export const AuthContext = React.createContext(null);

export const ContextProvider = (props) => {
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [userInfos, setUserInfos] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedLeadId, setSelectedLeadId] = React.useState(null);
  React.useEffect(() => {
    const unsubscriber = onAuthStateChanged(firebaseAuth, async (user) => { // Added async
      setIsLoading(true);
      if (user) {
        setIsSignedIn(true);
        setUser(user);
        try {
          // Waits for getUserInfos to finish before proceeding
          const userInfo = await getUserInfos(user.uid); 
          setUserInfos(userInfo);  // Assuming this setUserInfos refers to a state updater and not the same name function.
          console.log("getUserInfos : ", userInfo);
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      } else {
        setIsSignedIn(false);
        setUser(null);
        setUserInfos({});
      }
      setIsLoading(false);
    });
    // Return a function that unregisters the listener when the component unmounts
    return unsubscriber; 
  }, []);

  const signIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      );
      console.info("User signed in successfully : ", userCredential.user);
      return;
    } catch (err) {
      console.error(err.message);
      return err.message;
    }
  };

  const getUserInfos = async (uid) => {
    try {
      const response = await axiosInstance.get(`firestore/get_fb_user?fb_uid=${uid}`);
      setUserInfos(response.data);
      console.info("Fetch client infos successfully : ", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching conversation:", error);
    }
  };

  const signUp = async (email, password, name) => {
    try {
      await createUserWithEmailAndPassword(firebaseAuth, email, password).then(
        () => {
          updateProfile(firebaseAuth.currentUser, {
            displayName: name,
          });
        }
      );
      console.info("User signed up successfully");
      return;
    } catch (err) {
      console.error(err.message);
      return err.message;
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(firebaseAuth);
      console.info("User signed out successfully");
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        user,
        userInfos,
        isLoading,
        selectedLeadId,
        signIn,
        signUp,
        signOut,
        setSelectedLeadId,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
