import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from "react-i18next";

const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, body }) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            {body}
            </ModalBody>
            <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onConfirm}>
                {t("delete")}
            </Button>
            <Button variant="ghost" onClick={onClose}>{t("cancel")}</Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    );
};

export default ConfirmationModal;