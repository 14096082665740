import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import RTLLayout from "layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { SettingsProvider } from "./components/settings/SettingsContext";
import i18n from "config/i18n";
import { I18nextProvider } from "react-i18next";
import { ContextProvider, AuthContext } from "contexts/AuthContext";
import LoadingSpinner from './components/spinner/spinner';

const useAuth = () => {
  // Custom hook to use the AuthContext
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isSignedIn, isLoading } = useAuth(); // Fetch authentication state

  return (
    <Route {...rest} render={props => {
      if (isLoading) {
        // Show a spinner while the authentication status is loading
        return <LoadingSpinner />;
      }
      return isSignedIn
        ? <Component {...props} />
        : <Redirect to="/auth/sign-in" />;
    }} />
  );
};

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ChakraProvider theme={theme}>
      <SettingsProvider>
        <React.StrictMode>
          <ContextProvider>
            <HashRouter>
              <Switch>
                <Route path={`/auth`} component={AuthLayout} />
                <PrivateRoute path={`/admin`} component={AdminLayout} />
                <Route path={`/rtl`} component={RTLLayout} />
                <Redirect from="/" to="/admin/default" />
              </Switch>
            </HashRouter>
          </ContextProvider>
        </React.StrictMode>
      </SettingsProvider>
    </ChakraProvider>
  </I18nextProvider>,
  document.getElementById("root")
);
