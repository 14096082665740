// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
}

.stats-container {
    display: flex;
    height: calc(100vh - 80px);
    flex-direction: column;
    gap: 2em;
    overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/views/admin/default/Default.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,0BAA0B;IAC1B,sBAAsB;IACtB,QAAQ;IACR,gBAAgB;AACpB","sourcesContent":["html, body {\n    margin: 0;\n    padding: 0;\n    height: 100%;\n    overflow: hidden;\n}\n\n.stats-container {\n    display: flex;\n    height: calc(100vh - 80px);\n    flex-direction: column;\n    gap: 2em;\n    overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
